import {
  McButton,
  McCard,
  McHint,
  McIcon,
  McStepIndicator,
  McTab,
  McTabBar,
  McTag
} from '@maersk-global/mds-react-wrapper';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ShowErrorNotification, ShowSuccessNotification } from '../common/notification.jsx';
import { formatDateTime, getGitHubLink } from '../components/Util.js';
import Loading from '../components/pages/Loading.jsx';
import LoadRegion, { LoadRegionData } from '../components/testconfig/LoadRegion.jsx';
import TestConfig, {
  TestConfigData,
  TestConfigDataValidator
} from '../components/testconfig/TestConfig.jsx';
import ComponentLink from '../components/util/ComponentLink.jsx';
import ConfirmDeletionModal from '../components/util/ConfirmDeletionModal.jsx';
import TestExecService from '../service/TestExecService.js';
import { LogsButton, MetricsButton } from '../testExecution/Dashboard';
import FailureDetailsTab from '../testExecution/FailureInfo.jsx';
import TestInfo, { TestTypeInfo } from '../testExecution/TestInfo.jsx';
import TestReportTab from '../testExecution/TestReport';
import { StandardDirectoryLayoutNotFollowed } from './mdsAddTest.js';

export const ConfigureTest = () => {
  const testExecService = new TestExecService();
  const navigate = useNavigate();
  const location = useLocation();
  const { testId } = useParams();

  const [activeTab, setActiveTab] = useState(0);

  const [confirmStop, setConfirmStop] = useState(false);
  const [testData, setTestData] = useState(ConfigureTestUtil.getDefaultTestData());

  // useEffect(() => {
  //   if (!testData.testId) {
  //     return;
  //   }
  //   const currentTime = new Date().getTime();
  //   const fifteenMinutesBefore = currentTime - 15 * 60 * 1000;

  //   const dashboardUrlBase = testData.testTemplate.dashboardUrl;
  //   const params = new URLSearchParams({
  //     orgId: 1,
  //     'var-test_id': testData.testId,
  //     from: fifteenMinutesBefore,
  //     to: currentTime,
  //     refresh: '30s'
  //   });

  //   setDashboardUrl(`${dashboardUrlBase}?${params.toString()}`);
  // }, [testData]);
  const isNew = testId ? false : true;
  const isDraft = isNew || testData.status === 'DRAFT';

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);

  const steps = ['Saved', 'Submitted', 'Started', 'Completed'];
  const stepsStatus = [
    'DRAFT',
    'SUBMITTED | FAILED_TO_START | PUBLISH_TEST',
    'START_TEST | FAILED_TO_COMPLETE | STOP_REQUESTED | FAILED_TO_STOP',
    'COMPLETED | STOPPED'
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (testId) {
        // view/edit flow
        try {
          let response = await testExecService.getById(testId);
          setTestData(response.data);

          let status = response.data.status;
          if (
            status === 'COMPLETED' ||
            status === 'FAILED_TO_START' ||
            status === 'FAILED_TO_COMPLETE'
          ) {
            setActiveTab(3);
          }

          setApiStatus({ status: Status.Success, message: '' });
        } catch (error) {
          console.error('Error while getting test execution details ', error);
          let message = 'Something unexpected happened. Please try again by refreshing the page.';
          if (error.response && error.response.status === 404) {
            message = `Test execution ${testId} not found.`;
          }
          setApiStatus({
            status: Status.Error,
            message: message
          });
        }
      } else if (location.state?.templateData) {
        // create flow
        let testTemplate = location.state.templateData;
        // get test config from GitHub
        try {
          let config = await testExecService.getConfigFromGitHub(testTemplate);
          if (config.optionsJsonNotFound) {
            // let user know but allow test execution
            setApiStatus({
              status: Status.Warning,
              message: <StandardDirectoryLayoutNotFollowed />
            });
          } else {
            setApiStatus({ status: Status.Success, message: '' });
          }

          setTestData({
            ...testData,
            testTemplate: testTemplate,
            optionsJson: config.optionsJson,
            varsJson: config.varsJson
          });
        } catch (error) {
          console.error('Error while getting test config from GitHub ', error);
          setApiStatus({
            status: Status.Error,
            message: 'Something unexpected happened. Please try again.'
          });
          setTimeout(() => {
            navigate('/perf-testing/templates');
          }, 1000);
          return;
        }
      } else {
        // create flow from scratch
        setApiStatus({
          status: Status.NotSupported,
          message: ''
        });
      }
    };

    setApiStatus({ status: Status.Pending, message: '' });

    fetchData();
  }, [testId]);

  const handleSaveDraft = () => {
    setApiStatus({ status: Status.InProgress, message: '' });

    let testRequestData = {};
    try {
      testRequestData = ConfigureTestUtil.validateAndGetTestRequestData(testData);
    } catch (error) {
      setApiStatus({ status: Status.Error, message: error.message });
      return;
    }

    testExecService
      .createOrUpdate(testRequestData, isNew)
      .then((response) => {
        setApiStatus({
          status: Status.Success,
          message: 'Test execution saved successfully. Please continue to edit or start the test.'
        });

        if (isNew) {
          navigate(`/perf-testing/executions/${response.data.testId}`);
        } else {
          setTestData(response.data);
        }
      })
      .catch((error) => {
        console.error('Error while saving test execution: ', error);
        let message = 'Something unexpected happened. Please try again.';
        if (error.response && error.response.status === 400) {
          message = `${error.response.data.message}`;
        }
        setApiStatus({ status: Status.Error, message: message });
      });
  };

  const handleStopTest = (event, action) => {
    setConfirmStop(false);
    if (action === 'cancel') {
      return;
    }
    handleStartOrStopTest('stop');
  };

  const handleStartOrStopTest = (action) => {
    setApiStatus({ status: Status.InProgress, message: '' });

    testExecService
      .startOrStopTest(testData.testId, action)
      .then((response) => {
        let message = `Request to ${action} test submitted successfully. It can take upto 3 mins for test to start and metrics to show up in the dashboard.`;
        if (action === 'stop') {
          message =
            'Request to stop test submitted successfully. It can take upto 3 mins for test to stop. Please check the status of test after sometime.';
        }
        setApiStatus({
          status: Status.Success,
          message: message
        });
        if (action === 'restart') {
          navigate(`/perf-testing/executions/${response.data.testId}`);
        } else {
          setTestData(response.data);
        }
      })
      .catch((error) => {
        console.error('Error while starting/restarting/stopping test execution: ', error);
        setApiStatus({
          status: Status.Error,
          message: 'Something unexpected happened. Please try again.'
        });
      });
  };

  const handleScheduleButton = () => {
    navigate(`/perf-testing/schedules/new`, { state: { testExecutionData: testData } });
  };
  const handleIntegrationButton = () => {
    navigate(`/perf-testing/integrations/new`, { state: { testExecutionData: testData } });
  };
  const getRefreshData = async () => {
    setApiStatus({ status: Status.InProgress, message: '' });
    try {
      let response = await testExecService.getById(testId);
      setTestData(response.data);
      setApiStatus({ status: Status.Success, message: '' });
    } catch (error) {
      console.error('Error while getting test execution details ', error);
      setApiStatus({
        status: Status.Error,
        message: 'Something unexpected happened. Please try again.'
      });
    }
  };

  const getActionButtons = () => {
    const status = testData.status;
    let buttons = [];

    if (status === 'NEW' || status === 'DRAFT') {
      buttons.push(
        <McButton
          key="save-draft"
          loading={apiStatus.status === Status.InProgress}
          click={handleSaveDraft}>
          Save Draft
        </McButton>
      );
    }
    if (status === 'DRAFT') {
      buttons.push(
        <McButton
          key="start-test"
          loading={apiStatus.status === Status.InProgress}
          click={(event) => handleStartOrStopTest('start')}>
          Start Test
        </McButton>
      );
    }
    if (
      status === 'SUBMITTED' ||
      status == 'PUBLISH_TEST' ||
      status == 'START_TEST' ||
      status === 'STOP_REQUESTED'
    ) {
      buttons.push(
        <McButton
          loading={apiStatus.status === Status.InProgress}
          key="refresh"
          click={getRefreshData}>
          Refresh
        </McButton>
      );
    }
    if (status === 'START_TEST' || status === 'FAILED_TO_STOP') {
      buttons.push(
        <McButton
          key="stop-test"
          appearance="error"
          loading={apiStatus.status === Status.InProgress}
          click={(event) => setConfirmStop(true)}>
          Stop Test
        </McButton>
      );
    }
    if (
      status === 'FAILED_TO_START' ||
      status === 'FAILED_TO_COMPLETE' ||
      status === 'STOPPED' ||
      status === 'COMPLETED'
    ) {
      buttons.push(
        <McButton
          key="restart-test"
          loading={apiStatus.status === Status.InProgress}
          click={(event) => handleStartOrStopTest('restart')}>
          Restart Test
        </McButton>
      );
    }
    if (status === 'COMPLETED') {
      buttons.push(
        <McButton key="schedule-test" click={handleScheduleButton}>
          Schedule Test
        </McButton>
      );
      buttons.push(
        <McButton key="integration-test" click={handleIntegrationButton}>
          Integrate With Github Actions
        </McButton>
      );
    }

    return <>{buttons}</>;
  };

  const getInitContent = () => {
    if (apiStatus.status === Status.Pending) {
      return <Loading />;
    } else if (apiStatus.status === Status.NotSupported) {
      return <CreateNewTestExecutionHint />;
    }
  };

  const setTestConfigData = (configJson) => {
    setTestData({
      ...testData,
      optionsJson: configJson.optionsJson,
      varsJson: configJson.varsJson
    });
  };

  const setLoadRegionData = (selectedLoadRegions) => {
    setTestData({ ...testData, selLoadGeneratorList: selectedLoadRegions });
  };

  const onError = (message) => {
    setApiStatus({ status: Status.Error, message: message });
  };

  const clearError = () => {
    setApiStatus({ status: Status.Success, message: '' });
  };

  const getStepsIndicator = () => {
    return (
      <McStepIndicator
        fit="small"
        alignitemsdisabled={true}
        currentindex={stepsStatus.findIndex((status) => status.includes(testData.status)) + 1}
        labels={steps}></McStepIndicator>
    );
  };

  const getTags = () => {
    let tags = [];
    let statusAppearance = 'info';
    if (testData.status === 'COMPLETED') {
      statusAppearance = 'success';
    } else if (
      testData.status === 'FAILED_TO_START' ||
      testData.status === 'FAILED_TO_COMPLETE' ||
      testData.status === 'FAILED_TO_STOP'
    ) {
      statusAppearance = 'error';
    }
    tags.push(
      <McTag key="status" appearance={statusAppearance}>
        Status: {testData.status}
      </McTag>
    );
    if (testData.createdBy && !testData.scheduleTestId && !testData.testIntegrationId)
      tags.push(
        <McTag key="savedBy" appearance="info">
          Last Saved By: {testData.createdBy}
        </McTag>
      );
    if (testData.startedBy) {
      if (testData.scheduleTestId) {
        tags.push(
          <McTag key="startedBy">
            Started By:&nbsp;
            <ComponentLink route={`/perf-testing/schedules/${testData.scheduleTestId}`}>
              {testData.startedBy}
            </ComponentLink>
            &nbsp;
            <McIcon icon="clock-alarm" />
          </McTag>
        );
      } else if (testData.testIntegrationId) {
        tags.push(
          <McTag key="startedBy">
            Started By:&nbsp;
            <ComponentLink route={`/perf-testing/integrations/${testData.testIntegrationId}`}>
              {testData.startedBy}
            </ComponentLink>
            &nbsp;
            <McIcon icon="wrench" />
          </McTag>
        );
      } else {
        tags.push(
          <McTag key="startedBy" appearance="info">
            Started By: {testData.startedBy}
          </McTag>
        );
      }
    }
    if (testData.stoppedBy)
      tags.push(
        <McTag key="stoppedBy" appearance="info">
          Stopped By: {testData.stoppedBy}
        </McTag>
      );
    if (testData.createDateTime && !testData.scheduleTestId && !testData.testIntegrationId)
      tags.push(
        <McTag key="savedAt" appearance="info">
          Last Saved At: {formatDateTime(testData.createDateTime)}
        </McTag>
      );
    if (testData.startDateTime)
      tags.push(
        <McTag key="startedAt" appearance="info">
          Started At: {formatDateTime(testData.startDateTime)}
        </McTag>
      );
    if (testData.stopDateTime)
      tags.push(
        <McTag key="completedAt" appearance="info">
          Completed At: {formatDateTime(testData.stopDateTime)}
        </McTag>
      );
    tags.push(
      <McTag key="templateName">
        Template Name:&nbsp;
        <ComponentLink route={`/perf-testing/templates/${testData.testTemplate.templateId}`}>
          {testData.testTemplate.templateName}
        </ComponentLink>
      </McTag>
    );
    tags.push(
      <McTag key="testScript">
        Test Script:&nbsp;
        <a href={getGitHubLink(testData.testTemplate)} target="_blank" rel="noopener noreferrer">
          {testData.testTemplate.testScript}
        </a>
        &nbsp;
        <McIcon icon="square-arrow-up-right" />
      </McTag>
    );
    if (testData.testTemplate && testData.testTemplate.dashboardUrl) {
      tags.push(
        <McTag key="dashboardUrl">
          App Metrics:&nbsp;
          <a href={testData.testTemplate.dashboardUrl} target="_blank" rel="noopener noreferrer">
            Dashboard
          </a>
          &nbsp;
          <McIcon icon="square-arrow-up-right" />
        </McTag>
      );
    }

    return <>{tags}</>;
  };

  return (
    <div style={{ width: '100%' }}>
      <h5 className="app__page-title">
        Performance Engineering &gt; Test Executions &gt;{' '}
        {isNew ? 'New Test Execution' : testData.testId}
      </h5>
      {apiStatus.status === Status.Pending || apiStatus.status === Status.NotSupported ? (
        getInitContent()
      ) : (
        <>
          <div>
            {apiStatus.status === Status.Success && apiStatus.message ? (
              <ShowSuccessNotification message={apiStatus.message} />
            ) : null}
            {apiStatus.status === Status.Error || apiStatus.status === Status.Warning ? (
              <ShowErrorNotification
                message={apiStatus.message}
                severity={apiStatus.status === Status.Error ? 'error' : 'warning'}
              />
            ) : null}
            {confirmStop ? (
              <ConfirmDeletionModal
                actionName="Stop"
                confirmationMessage="Are you sure you want to stop the test?"
                onAction={handleStopTest}
              />
            ) : null}
            <div style={{ display: 'flex', gap: '48px', alignItems: 'flex-start' }}>
              <div style={{ flexGrow: 0.6 }}>
                <McTabBar
                  currentindex={activeTab}
                  tabchange={(event) => setActiveTab(event.detail)}>
                  <McTab slot="tab" label="Test Info" />
                  <div slot="panel">
                    <TestInfo isEdit={isDraft} testData={testData} setTestData={setTestData} />
                  </div>
                  <McTab slot="tab" label="Test Config" />
                  <div slot="panel">
                    <TestConfig
                      isEdit={isDraft}
                      testConfigData={testData}
                      setTestConfigData={setTestConfigData}
                    />
                  </div>
                  <McTab slot="tab" label="Load Region" />
                  <div slot="panel">
                    <LoadRegion
                      isEdit={isDraft}
                      loadRegionData={testData.selLoadGeneratorList}
                      setLoadRegionData={setLoadRegionData}
                    />
                  </div>
                  {testData.status === 'COMPLETED' ? (
                    <>
                      <McTab slot="tab" label="Test Report" />
                      <div slot="panel">
                        <TestReportTab
                          testData={testData}
                          onError={onError}
                          clearError={clearError}
                        />
                      </div>
                    </>
                  ) : null}
                  {testData.status === 'FAILED_TO_START' ||
                  testData.status === 'FAILED_TO_COMPLETE' ? (
                    <>
                      <McTab slot="tab" label="Failure Info" />
                      <div slot="panel">
                        <FailureDetailsTab testData={testData} onError={onError} />
                      </div>
                    </>
                  ) : null}
                </McTabBar>
                <div className="app__page-footer">{getActionButtons()}</div>
              </div>
              <div style={{ marginTop: '48px', height: '552px' }}>
                <McCard>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px'
                    }}>
                    <div style={{ marginBottom: '16px' }}>{getStepsIndicator()}</div>
                    {getTags()}
                  </div>
                  <div slot="actions" style={{ display: 'flex', gap: '16px' }}>
                    <LogsButton testData={testData} />
                    <MetricsButton testData={testData} />
                  </div>
                </McCard>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ConfigureTest;

export const CreateNewTestExecutionHint = () => (
  <McHint>
    <McIcon icon="info-circle" />
    <span>
      To create a new test execution, please navigate to Test Templates &gt; Execute Test.
    </span>
  </McHint>
);

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error',
  Warning: 'Warning',
  NotSupported: 'NotSupported'
};

class ConfigureTestUtil {
  static getDefaultTestData() {
    let defaultTestData = { ...TestTypeInfo };
    defaultTestData.configType = TestConfigData.configType;
    defaultTestData.optionsJson = TestConfigData.optionsJson;
    defaultTestData.varsJson = TestConfigData.varsJson;
    defaultTestData.selLoadGeneratorList = [...LoadRegionData];
    return defaultTestData;
  }

  static validateAndGetTestRequestData(testData) {
    if (testData.testName.trim() === '') {
      throw Error('Test name is required.');
    }
    let validationError = TestConfigDataValidator(testData);
    if (validationError) {
      throw Error(validationError);
    }
    if (testData.selLoadGeneratorList.length === 0) {
      throw Error('At least one load region is required.');
    }
    let requestData = {
      testId: testData.testId,
      testName: testData.testName,
      testType: testData.testType,
      testTemplate: { templateId: testData.testTemplate.templateId },
      optionsJson: testData.optionsJson,
      varsJson: testData.varsJson,
      selLoadGeneratorList: testData.selLoadGeneratorList
    };
    if (testData.status !== 'NEW') {
      requestData.status = testData.status;
    }
    return requestData;
  }
}
