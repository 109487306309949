import {
  McTooltip,
  McButton,
  McInput,
  McNotification,
  McOption,
  McSelect,
  McToast,
  McIcon
} from '@maersk-global/mds-react-wrapper';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCoreServiceUrl, getOrchestratorUrl } from '../components/Util.js';
import ConfirmDeletionModal from '../components/util/ConfirmDeletionModal.jsx';
import UserInfo from '../hooks/UserAppAuthorizationProvider.jsx';
import GetTestScriptDataService from '../service/GetTestScriptDataService.js';
import TestConfigService from '../service/TestConfigService.js';
import TestTmplService from '../service/TestTmplService.js';
import '../stylesheets/Vault.css';
let config = require('../config/config.json');

const MdsAddTest = () => {
  const testTmplService = new TestTmplService();

  const navigate = useNavigate();
  const { graphData, platformName, applicationName } = UserInfo();
  const tmplStatusList = config.templateStatuses;
  const envList = config.environment;

  const [optionSelected] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [dataFileList, setDataFileList] = useState('');
  const [formattedData, setFormattedData] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);

  const templateId = useParams().templateId ? useParams().templateId : '';
  const edit = templateId ? true : false;

  const [loadingVal, setLoadingVal] = useState(true);
  const [isIconClicked, setIconClicked] = useState(false);

  const [userForm, setUserForm] = React.useState({
    templateName: '',
    repoName: '',
    branchName: '',
    folderLocation: '',
    testScript: '',
    environment: 'dev',
    templateStatus: 'ACTIVE',
    dataFileList: '',
    createdBy: '',
    updatedBy: '',
    dashboardUrl: ''
  });
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  let testConfigService = new TestConfigService();

  useEffect(() => {
    if (edit) {
      setLoadingVal(true);
      getData();
    } else {
      setLoadingVal(false);
    }
  }, []);

  const getData = async () => {
    if (edit === true) {
      try {
        const response = await testTmplService.getTestTemplateById(
          getCoreServiceUrl() + '/core-srv/test-tmpl/' + templateId
        );
        if (response.status == 200) {
          setLoadingVal(false);
          const updatedUserForm = {
            ...userForm,
            templateName: response.data.templateName,
            repoName: response.data.repoName,
            branchName: response.data.branchName,
            folderLocation: response.data.testScript.split('/')[0],
            testScript: response.data.testScript,
            environment: response.data.environment,
            templateStatus: response.data.status,
            createdBy: response.data.createdBy,
            updatedBy: response.data.updatedBy
          };
          if (response.data.dashboardUrl) {
            updatedUserForm.dashboardUrl = response.data.dashboardUrl;
          }
          setUserForm(updatedUserForm);
        } else {
          let error = new Error('Error while getting test template by id');
          error.response = response;
          throw error;
        }
      } catch (error) {
        console.error('Error while getting test template by id ', error);
        setShowAlert(true);
        setAlertType('error');
        setAlertMessage(error.response?.data?.message);
      }
    }
  };

  const getOptionsFile = async (testScript) => {
    let testTemplate = {
      repoName: userForm.repoName,
      branchName: userForm.branchName,
      testScript: testScript,
      environment: userForm.environment
    };
    // get test config from GitHub
    try {
      let config = await testConfigService.getConfigFromGitHub(testTemplate);
      if (config.optionsJsonNotFound) {
        setShowAlert(true);
        setAlertType('fyi');
      }
    } catch (error) {
      console.error('Error while getting test config from GitHub ', error);
    }
  };

  const getGitAllFiles = async (folderLocationVal) => {
    if (userForm.repoName === '') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Please enter GitHub repository name');
    } else if (folderLocationVal === '') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage(
        'Please enter folder which contains your test script inside your GIT repository'
      );
    } else if (userForm.branchName === '') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Please enter GIT branch name');
    } else {
      let gitDataBody = {
        repoName: userForm.repoName,
        filePath: userForm.folderLocation,
        branchName: userForm.branchName
      };

      let getTestScriptDataService = new GetTestScriptDataService();
      getTestScriptDataService
        .getTestScriptData(getOrchestratorUrl() + '/git/get-list?filetype=js', gitDataBody)
        .then((response) => {
          if (response.status == 200 && response.data !== undefined) {
            setDataFileList(response.data);

            setUserForm({
              ...userForm,
              dataFileList: response.data
            });
          } else {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
            setShowAlert(true);
            setAlertType('error');
            setAlertMessage('No javascript files found in the folder ' + folderLocationVal);
          }
        })
        .catch((error) => {
          console.log('Error =', error);
          if (error instanceof Error) {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
              /* you can also use 'auto' behaviour
                 in place of 'smooth' */
            });
            setShowAlert(true);
            setAlertType('error');
            setAlertMessage('Error Occured' + error.message);
          }
        });
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (edit === true) {
      let sdata = {
        status: userForm.templateStatus,
        testScript: userForm.testScript,
        templateName: userForm.templateName,
        repoName: userForm.repoName,
        environment: userForm.environment,
        branchName: userForm.branchName
      };
      if (userForm.dashboardUrl) {
        sdata.dashboardUrl = userForm.dashboardUrl;
      }
      testTmplService
        .updateTestTemplate(getCoreServiceUrl() + '/core-srv/test-tmpl/' + templateId, sdata)
        .then((response) => {
          if (response.status == 200) {
            setShowAlert(true);
            setAlertType('success');
            setIsFormSaved(true);
            setIsUpdated(true); // Mark as updated
            setAlertMessage('Successfully updated test template');
          } else {
            let error = new Error('Error while updating test template');
            error.response = response;
            throw error;
          }
        })
        .catch((error) => {
          console.error('Error while updating test template ', error);
          setShowAlert(true);
          setAlertType('error');
          setAlertMessage(error.response?.data?.message);
        });
    } else {
      if (optionSelected != null) {
        optionSelected.map((data) => {
          const modifiedJsonData = { ...data };
          delete modifiedJsonData.label;
          delete modifiedJsonData.value;
        });
      }
      let data = {
        testScript: userForm.testScript,
        status: userForm.templateStatus,
        templateName: userForm.templateName,
        repoName: userForm.repoName,
        environment: userForm.environment,
        branchName: userForm.branchName
      };
      if (userForm.dashboardUrl) {
        data.dashboardUrl = userForm.dashboardUrl;
      }

      testTmplService
        .postTestTemplate(getCoreServiceUrl() + '/core-srv/test-tmpl', data)
        .then((response) => {
          if (response.status == 200) {
            setShowAlert(true);
            setAlertType('success');
            setIsFormSaved(true);
            setAlertMessage('Successfully created test template');
          } else {
            let error = new Error('Error while creating test template');
            error.response = response;
            throw error;
          }
        })
        .catch((error) => {
          console.log('error', error);
          setShowAlert(true);
          setAlertType('error');
          setAlertMessage(error.response?.data?.message);
        });
    }
  };

  const handleDelete = async (event, action) => {
    setConfirmDelete(false);
    if (action === 'cancel') {
      return;
    }
    try {
      const response = await testTmplService.deleteTestTemplate(
        getCoreServiceUrl() + '/core-srv/test-tmpl/' + templateId
      );
      const { data } = response;
      let formattedData = '';

      if (response.status === 200) {
        setShowAlert(true);
        setAlertType('success');
        setAlertMessage('Successfully deleted test template ' + templateId);
        setTimeout(() => {
          navigate('/perf-testing/templates');
        }, 2000);
      } else if (response.status === 409) {
        let integrationMessage = '';
        let scheduleMessage = '';

        if (data.testIntegrations && data.testIntegrations.length > 0) {
          const integrations = data.testIntegrations
            .map(({ id, name }) => `ID: ${id}, Name: ${name}`)
            .join('\n');
          integrationMessage = `Template is part of active CICD integrations. To delete this template, first delete these CICD integrations:\n\n${integrations}\n\n`;
        }

        if (data.testSchedules && data.testSchedules.length > 0) {
          const schedules = data.testSchedules
            .map(({ id, name }) => `ID: ${id}, Name: ${name}`)
            .join('\n');
          scheduleMessage = `Template is part of active test schedules. To delete this template, first delete these test schedules:\n\n${schedules}\n\n`;
        }

        formattedData = `${integrationMessage}${scheduleMessage}`.trim();

        setShowAlert(true);
        setAlertType('For your information');
        setFormattedData(formattedData);
      }
    } catch (error) {
      console.error('Error while deleting test template', error);
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage(
        error.response?.data?.message || 'An error occurred while deleting the test template.'
      );
    }
  };

  function errorDialogOnClose() {
    setShowAlert(false);
  }

  function showAlertDialog() {
    let icon;

    if (alertType === 'info') {
      icon = 'info-circle';
    } else if (alertType === 'error') {
      icon = 'exclamation-octagon';
    } else if (alertType === 'success') {
      icon = 'check-circle';
    }
    if (alertType === 'fyi') {
      return (
        <McToast
          open
          position="top-center"
          appearance="info"
          close={errorDialogOnClose}
          duration="5000">
          <McNotification icon={icon} fit="large">
            <StandardDirectoryLayoutNotFollowed />
          </McNotification>
        </McToast>
      );
    }
    if (alertType === 'For your information') {
      return (
        <McToast
          open
          position="top-center"
          appearance="info"
          close={errorDialogOnClose}
          duration="7000">
          <McNotification icon={icon}>
            <div
              style={{
                whiteSpace: 'pre-line',
                maxHeight: '50vh',
                overflowY: 'auto'
              }}>
              {formattedData}
            </div>
          </McNotification>
        </McToast>
      );
    } else {
      return (
        <McToast
          open
          position="top-center"
          appearance={alertType}
          close={errorDialogOnClose}
          duration="5000">
          <McNotification icon={icon} body={alertMessage}></McNotification>
        </McToast>
      );
    }
  }

  let alertDia = showAlertDialog();

  return (
    <>
      <div>
        {showAlert ? alertDia : ''}
        {confirmDelete ? (
          <ConfirmDeletionModal
            confirmationMessage="Are you sure you want to delete this template?"
            onAction={handleDelete}
          />
        ) : null}
        <h5 className="app__page-title">
          Performance Engineering &gt; Test Templates &gt;{' '}
          {edit ? 'Edit Test Template' : 'Add Test Template'}
        </h5>
        <form id="info" onSubmit={(e) => handleSubmit(e)}>
          <div id="Personal">
            <div className="app__page-input">
              <McInput
                name="templateName"
                label="Template Name"
                placeholder="Enter Template name"
                value={loadingVal ? 'Loading ...' : userForm.templateName}
                required
                input={(event) => {
                  if (event) {
                    const value = event.target.value;
                    setUserForm({ ...userForm, templateName: value });
                  }
                }}></McInput>
            </div>
            <div className="app__page-input">
              <McInput
                name="repoName"
                label="Git Repository Name"
                placeholder="Enter Git Repository Name"
                value={loadingVal ? 'Loading ...' : userForm.repoName}
                required
                input={(event) => {
                  if (event) {
                    const value = event.target.value;
                    setUserForm({ ...userForm, repoName: value });
                  }
                }}></McInput>
            </div>
            <div className="app__page-input">
              <McInput
                name="branchName"
                label="Git Branch Name"
                placeholder="Enter Git Branch Name"
                value={loadingVal ? 'Loading ...' : userForm.branchName}
                required
                input={(event) => {
                  if (event) {
                    const value = event.target.value;
                    setUserForm({ ...userForm, branchName: value });
                  }
                }}></McInput>
            </div>
            <div className="app__page-input">
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <div style={{ flex: 0.9 }}>
                  <McInput
                    name="folderLocation"
                    label="Folder Location"
                    placeholder="Enter Folder Location"
                    value={loadingVal ? 'Loading ...' : userForm.folderLocation}
                    required={edit ? false : true}
                    input={(event) => {
                      if (event) {
                        const value = event.target.value;
                        setUserForm({ ...userForm, folderLocation: value });
                      }
                    }}></McInput>
                </div>
                <div style={{ flex: 0.1 }}>
                  <McTooltip>
                    <McButton
                      slot="trigger"
                      appearance="neutral"
                      variant="plain"
                      click={() => {
                        getGitAllFiles(userForm.folderLocation);
                        setIconClicked(true);
                      }}>
                      <McIcon icon="magnifying-glass"></McIcon>
                    </McButton>
                    <span>Click to search for files in specified folder</span>
                  </McTooltip>
                </div>
              </div>
            </div>
            <div className="app__page-input">
              {isIconClicked ? (
                <McSelect
                  name="testScript"
                  label="Test script"
                  required
                  optionselected={(event) => {
                    setUserForm({
                      ...userForm,
                      testScript: event.detail.value
                    });
                    getOptionsFile(event.detail.value);
                  }}>
                  {dataFileList &&
                    dataFileList.map((unit, index) => (
                      <McOption key={index} value={unit}>
                        {unit}
                      </McOption>
                    ))}
                </McSelect>
              ) : (
                <McTooltip position="bottom-left">
                  <McInput
                    slot="trigger"
                    name="testScript"
                    label="Test script"
                    value={userForm.testScript}
                    disabled={true}></McInput>
                  <span>Please click on search icon to see available test scripts</span>
                </McTooltip>
              )}
            </div>
            <div className="app__page-input">
              <McSelect
                name="environment"
                label="Test Environment"
                required
                value={userForm.environment}
                optionselected={(event) => {
                  setUserForm({
                    ...userForm,
                    environment: event.detail.value
                  });
                }}>
                {envList &&
                  envList.map((env, index) => (
                    <McOption key={index} value={env.value}>
                      {env.name}
                    </McOption>
                  ))}
              </McSelect>
            </div>
            <div className="app__page-input">
              <McInput
                name="dashboardUrl"
                label="Metrics Dashboard"
                placeholder="Optional Grafana dashboard for app metrics"
                required
                value={userForm.dashboardUrl}
                input={(event) => {
                  if (event) {
                    const value = event.target.value;
                    setUserForm({ ...userForm, dashboardUrl: value });
                  }
                }}></McInput>
            </div>
            <div className="app__page-input">
              <McSelect
                name="templateStatus"
                label="Status"
                required
                value={userForm.templateStatus}
                optionselected={(event) => {
                  setUserForm({
                    ...userForm,
                    templateStatus: event.detail.value
                  });
                }}>
                {tmplStatusList &&
                  tmplStatusList.map((status, index) => (
                    <McOption key={index} value={status}>
                      {status}
                    </McOption>
                  ))}
              </McSelect>
            </div>
            <div className="app__page-input">
              {userForm.updatedBy ? (
                <McInput
                  name="updatedBy"
                  label="Updated By"
                  value={userForm.updatedBy}
                  disabled={true}
                />
              ) : (
                <McInput
                  name="createdBy"
                  label="Created By"
                  value={userForm.createdBy ? userForm.createdBy : graphData.name}
                  disabled={true}
                />
              )}
            </div>

            <div className="app__page-footer" style={{ display: 'flex', gap: '16px' }}>
              <McButton type="submit" disabled={isFormSaved}>
                {edit ? 'Update' : 'Save'}
              </McButton>
              {edit && (
                <McButton
                  type="button"
                  appearance="error"
                  click={() => {
                    setConfirmDelete(true);
                  }}>
                  Delete
                </McButton>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default MdsAddTest;

export const StandardDirectoryLayoutNotFollowed = () => {
  return (
    <span>
      Test script does not follow{' '}
      <a
        href="https://ipace-docs.maersk-digital.net/perf-testing/configuring-test.html"
        target="_blank"
        rel="noreferrer">
        recommended
      </a>{' '}
      directory layout and configuration best practices. You can still execute the test but some
      features will not work.
    </span>
  );
};
